.nav .nav-link {
  color: #000 !important;
}
.nav .active {
  background: #0b8 !important;
  color: #fff !important;
}
.overflow-container {
  overflow-x: auto;
  white-space: nowrap; /* Prevents wrapping of content */
}
