*{
  font-family: 'Inter', sans-serif;

}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', sans-serif;
}
.navbar-brand img{
  height:50px;
  width:100px;
}
.heading2{
  color: #47474f;
  font-size: 22px;
  font-weight:400;
}
.heading1{
  color: #000;
  font-size: 35px;
  font-weight: 700;
}
.pdf-btn{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  border-radius:8px;
  line-height: 1;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  min-width: 140px;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 0.7rem 1rem;
   color: #fff; 
  background-color: #0b8;

}
.pdf-btn:disabled {
  cursor: not-allowed;
  background: #ccc; /* Set a different background color for disabled state */
}

.pdf-btn:not(:disabled):hover {
  transform: scale(1.04);
  background: #059971;
}

.close-btn{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  min-width: 140px;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 1rem 1rem;
  color: #fff;
  background-color: #C70039;
}
.close-btn:hover{
  transform: scale(1.04);
  background:#ad2d13;
}
.modal-close-btn {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 25px;
 
  background-color:transparent; 
}
.option-btn{
  border: none;
  color: #fff;
  display: inline-block;
  border-radius:8px;
  outline: none;
  font-size: 16px;
  line-height: 1;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  min-width: 140px;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 0.7rem 1rem;
  background:#0b8;
   
}
.rotate-on-hover {
  transition: transform 0.5s; 
}

.rotate-on-hover:hover {
  transform: rotate(360deg);
}

.social_icon{
  padding:4px;
  border-radius:50%
}
.modal-body{
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.fa-regular{
  color:#0b8;;
}
.my-modal {
  height:600px;
}
.modal-content{
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border: 5px solid #02966f;
}
.select-file:hover{
  transform: scale(1.04);
  background:#02966f;
  color:white;
  cursor:pointer;
}
.nav-link{
  transition:none;
}
/* .drop-area{
  background:var(--primary);
} */
.list-unstyled li{
  color:var(--primary)
}
.canvas-delete-btn{
  padding:0px;
  margin:0px;
  border:none;
  outline:none;
  background:transparent;
 position:absolute;
 right:-10px;
 top:-14px;
 height:25px;
 width:25px;
 
}
.image-delete-btn{
  padding:0px;
  margin:0px;
  border:none;
  outline:none;
  background:transparent;
 position:absolute;
 right:-15px;
 top:-25px;
 height:25px;
 width:25px;
}
.image-delete-btn i:hover{
  background:#FF0800;
  color:#fff;
}


.canvas-delete-btn i{
  color:#000;
  transition-property: background-color,border-color,color,box-shadow,filter;
  font-size:15px;
  padding-top:0.50rem;
  align-item:center;
  font-weight:700;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11);  z-index: 1;
  background:#ebe8e8;
  border-radius:50%;
}
.canvas-delete-btn i:hover{
  background:#FF0800;
  color:#fff;
}
.image-delete-btn i{
  color:#000;
  transition-property: background-color,border-color,color,box-shadow,filter;
  font-size:15px;
  padding-top:0.50rem;
  align-item:center;
  font-weight:700;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .13), 0 1px 1px 0 rgba(0, 0, 0, .11);  z-index: 1;
  background:#ebe8e8;
  border-radius:50%;
}
.pdf-card{
  margin:10px 15px;
  width:158px;
  /* border:1px solid green; */
}
.image-to-pdf-card{
  margin:10px 12.5px;

}
.image-to-pdf-card:hover{
  transform: scale(1.0);
}
.merge-pdf-card{
  margin:0px 30px 10px 0px;
  width:158px
}
.pdf-card:hover{
  /* border:1px solid grey; */
  transform: scale(1.0);
}
.mobile-card:hover{
  transform: scale(1.0);

}
.compress-card:hover {
  transform: scale(1.0);
}
.card-img-top:hover{
  transform: scale(1.01);
}
.pdf-card canvas:hover{
border:1px solid grey;
border-radius:3px;
}
.navbar-parent{
  background:#fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.30);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.navbar-nav .active {
  border-bottom:3px solid #0b8;
  color:#0b8;
}
.main-pdf-body{
  margin-top:3rem;
}
@media (max-width: 575.98px) {
  .navbar-nav .active {
    border-bottom: none;
    color: #0b8;
  }
  /* .ms-auto{
    border-top:2px solid #0b8;
  } */
  .heading1{
    font-size:2rem;
  }
  .heading2{
    font-size:1.2rem;
  }
  .main-pdf-body{
    padding :0px 10px
  }
  .footer-btn button{
    margin:0 0 11px 0;
  }
  .my-small-modal{
    margin:5rem auto;
  }
  .my-small-modal h2 span{
    font-size:1.3rem;
  }

  .my-modal-header{
    padding:0px;
  }
  .main-pdf-body{
    margin-top:0px;
  }
  .my-custom-container {
    margin-right: 30px;
    margin-left: 30px;
  }
  .mobile-margin{
    margin-right:0.6rem;
  }
}
@media (max-width: 767px) {
  /* .canvas-container {
    flex-direction: column;
    align-items: center;
  } */
  .pdf-card {
    /* width: 100%; */
    flex-direction: column;
    align-items: center;
    margin:0.5rem auto auto  auto;
  }
}
.pdf-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.compress-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction : column;
}
.footer-btn button{
  /* margin:0 0px; */
}
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  right:0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1001; /* Make sure the overlay is on top of other elements */
}
.overlay.show {
  display: block;
}
.custom-color button{
  color:#0b8;
}
.name-box {
  background:#d7d7d8;
  width:158px !important;
  padding:  0.50rem;
  border-radius: 7px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  word-break: break-word;
}
.compress-pdf-box{
  background:#d7d7d8;
  width:80px !important;
  padding:  0.30rem 0rem;
  border-radius: 7px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  word-break: break-word;
  color:red;
}
.image-box{
  background:#d7d7d8;
  width:158px !important;
  border-radius: 7px;
  text-align: center;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
  word-break: break-word;
}
.name {
  font-size: 0.7rem;
  margin: 0;
  letter-spacing: .5px;
  text-transform:capitalize;
  color:#707070;
  padding:0 0.3rem;
}
/* App.css */

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f8f9fc; 
  z-index: 1000;
}

.footer-content {
  padding: 14px;
  text-align: end;
}
.custom-cloud{
  width:0px !important;
}
.compress-pdf-img {
  height:100px;
  width:100px;
}
.compress-pdf-down{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  border-radius:8px;
  line-height: 1;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  min-width: 65px;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 0.7rem 1rem;
   color: #fff; 
  background-color: #0b8;
}
.compress-pdf-down:hover{
  transform: scale(1.04);
  background: #059971;
}
.popup-btn{
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  border-radius:8px;
  line-height: 1;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  min-width: 90px;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  padding: 0.7rem 1rem;
   color: #fff; 
  background-color: #0b8;
}
.popup-btn:hover{
  transform: scale(1.04);
  background: #059971;
}

.resolution-slider-container {
  width: 80%;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.range-slider {
  width: 100%;
  margin-top: 10px;
}

.range-labels {
  color: #555;
  font-size: 14px;
}

.selected-range {
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #333;
}

.pdf-image {
  width: 50px;
  transition: transform 0.3s ease-in-out;
  }
  .my-h2 span{
    font-size:1.9rem
  }

  /* mega menu */
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu ul li {
    display: inline-block;
    position: relative;
  }
  
  .menu ul li a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #333;
  }
  
  .mega-menu {
    display: none;
    position: absolute;
    /* top: 100%; */
    /* left: 0; */
    /* right:50px; */
    width: 400px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2); /* Adjust this shadow */
    z-index: 1;
    border-radius:5px;
    transform: translateX(-25%);
  }
  
  .mega-menu .column {
    float: left;
    width: 50%;
  }
  .column h5{
    color:#0b8;
    font-weight:500;
    font-size:1rem;
    color:#000;
  }
  .mega-menu .column ul {
    list-style: none;
    padding: 0;
  }
  
  .mega-menu .column ul li a {
    display: block;
    padding: 5px 0;
    color: #666;
  }
  
  .mega-dropdown:hover .mega-menu {
    display: block;
  }
  @media (max-width: 500px) {
    .mega-menu {
     width:300px;
     transform: translateX(0%)!important;
    }
    .mega-menu .column {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    .mega-menu {
     width:300px;
     transform: translateX(-10%);
    }
  }
  .custom-range-container {
   
    /* width: 300px; */
    /* margin: 10px auto; */
  }
  
  .custom-range-container label {
    /* margin-right: 10px; */
  }
  .custom-range-container select {
    padding: 5px;
    /* margin-right: 10px; */
    border: 1px solid #ccc;
    border-radius: 3px;
    width:60px;
  }
  
  .custom-range-container select:focus {
    outline: none;
    border-color: #0b8;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  /* .custom-range-container button {
    border-radius: 4px;
    padding: 5px 15px;
    cursor: pointer;
    width:159px;
    border-color: 1px solid #e7e7e7;
    outline:none;
    color: black;
  }
  
  .custom-range-container button:hover {
    background-color: #e7e7e7;
  }
  .custom-range-container button:focus {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);    border:2px solid #0b8;
  } */
  .card-img-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 160px;
    height: 215px;
    object-fit: cover;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .card-img-top img{
    height:50px;
    width:50px;
  }
  .card-img-top p{
    margin:0 0 0.3rem 0;
    width:50px;
    background:#1665c0;
    color:#fff;
    font-weight:550;
    border-radius:2px;

  }
  .image-to-pdf-btn{
    border:none;
    width:150px;
    padding:0.7rem 0.7rem;
    border-radius:4px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-duration: .3s;
  white-space: normal;
  font-weight: 700;
  text-align: center;
   color: #fff; 
  background-color: #0b8;
  }
  .image-to-pdf-btn:hover{
    transform: scale(1.04);
    background: #059971;
  }
  .carousel-control-prev-icon, .carousel-control-next-icon {
    height: 100px;
    width: 100px;
    outline: black;
    background-color: #0b8;
    background-size: 100%, 100%;
    border-radius: 50%;
    /* border: 1px solid black; */
}
  
  
  